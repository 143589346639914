import httpClient from "../utils/helpers/httpClient";
import { BASE_URL } from "../utils/constants/url";
import {stringifyUrl} from "query-string";

export const countryReferenceProvider = {
  getList: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${BASE_URL}/clubs/${resource}`,
      query: { q: params.filter.q },
    });

    return httpClient(urlWithQueryParams).then(({ json }) => ({
      data: json,
      total: json?.length,
    }));
  },

  getMany: (resource, params) => {
    // In this case we don't need to make a request as the clubScopes is an enum.
    return Promise.resolve({ data: [], total: 0 });
  },
};
