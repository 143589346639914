import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
} from "react-admin";
import ListActions from "../../../components/actionButtons/ListActions";
import ClubDetailsField from "../../../components/fields/ClubDetailsField";
import LivestreamListFilters from "./LivestreamListFilters";
import isoDateToLocalDateString from "../../../utils/helpers/isoDateToLocalDateString";
import RESOURCES from "../../../utils/constants/resources";
import parseOwnerName from "../../../utils/helpers/parseOwnerName";
import LIVESTREAM_FIELDS from "../../../utils/constants/labels/livestreamFields";

const LivestreamList = () => (
  <List filters={LivestreamListFilters()} actions={<ListActions />}>
    <Datagrid rowClick="show">
      <DateField
        source={LIVESTREAM_FIELDS.startAt.source}
        label={LIVESTREAM_FIELDS.startAt.label}
        showTime
        transform={isoDateToLocalDateString}
      />
      <DateField
        source={LIVESTREAM_FIELDS.endAt.source}
        label={LIVESTREAM_FIELDS.endAt.label}
        showTime
        transform={isoDateToLocalDateString}
      />
      <TextField
        source={LIVESTREAM_FIELDS.homeTeam.source}
        label={LIVESTREAM_FIELDS.homeTeam.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.awayTeam.source}
        label={LIVESTREAM_FIELDS.awayTeam.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.livestreamMixedType.source}
        label={LIVESTREAM_FIELDS.livestreamMixedType.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.state.source}
        label={LIVESTREAM_FIELDS.state.label}
        emptyText="-"
        sortable={false}
      />
      <ClubDetailsField label="Organisation" />
      <ReferenceField
        source={LIVESTREAM_FIELDS.creator.source}
        label={LIVESTREAM_FIELDS.creator.label}
        reference={RESOURCES.users}
      >
        <FunctionField
          render={(record) => {
            const parsedOwnerName = parseOwnerName(record);
            return parsedOwnerName.substring(1, parsedOwnerName?.length - 1);
          }}
        />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default LivestreamList;
