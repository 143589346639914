import React from "react";
import {
  DateField,
  FunctionField,
  ImageField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import ShowActions from "../../components/actionButtons/ShowActions";
import ClubDetailsField from "../../components/fields/ClubDetailsField";
import isoDateToLocalDateString from "../../utils/helpers/isoDateToLocalDateString";
import LIVESTREAM_FIELDS from "../../utils/constants/labels/livestreamFields";
import RESOURCES from "../../utils/constants/resources";
import parseOwnerName from "../../utils/helpers/parseOwnerName";

const LivestreamShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField
        source={LIVESTREAM_FIELDS.name.source}
        label={LIVESTREAM_FIELDS.name.label}
        emptyText="-"
      />
      <DateField
        source={LIVESTREAM_FIELDS.startAt.source}
        label={LIVESTREAM_FIELDS.startAt.label}
        showTime
        transform={isoDateToLocalDateString}
      />
      <DateField
        source={LIVESTREAM_FIELDS.endAt.source}
        label={LIVESTREAM_FIELDS.endAt.label}
        showTime
        transform={isoDateToLocalDateString}
      />
      <ImageField
        source={LIVESTREAM_FIELDS.homeTeamLogo.source}
        label={LIVESTREAM_FIELDS.homeTeamLogo.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.homeTeam.source}
        label={LIVESTREAM_FIELDS.homeTeam.label}
        emptyText="-"
      />
      <ImageField
        source={LIVESTREAM_FIELDS.awayTeamLogo.source}
        label={LIVESTREAM_FIELDS.awayTeamLogo.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.awayTeam.source}
        label={LIVESTREAM_FIELDS.awayTeam.label}
        emptyText="-"
      />
      <TextField
        source={LIVESTREAM_FIELDS.livestreamMixedType.source}
        label={LIVESTREAM_FIELDS.livestreamMixedType.label}
        emptyText="-"
      />
      <Labeled style={{marginLeft: 50}} label={LIVESTREAM_FIELDS.livestreamType.label}>
        <TextField  source={LIVESTREAM_FIELDS.livestreamType.source} emptyText="-"/>
      </Labeled>
      <Labeled style={{marginLeft: 50}} label={LIVESTREAM_FIELDS.livestreamSaveType.label}>
        <TextField  source={LIVESTREAM_FIELDS.livestreamSaveType.source} emptyText="-"/>
      </Labeled>
      <TextField
        source={LIVESTREAM_FIELDS.state.source}
        label={LIVESTREAM_FIELDS.state.label}
        emptyText="-"
      />
      <ClubDetailsField label="Organisation" show />
      <ReferenceField
        source={LIVESTREAM_FIELDS.creator.source}
        label={LIVESTREAM_FIELDS.creator.label}
        reference={RESOURCES.users}
      >
        <FunctionField
          render={(record) => {
            const parsedOwnerName = parseOwnerName(record);
            return parsedOwnerName.substring(1, parsedOwnerName?.length - 1);
          }}
        />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default LivestreamShow;
