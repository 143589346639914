import { BASE_URL } from "../utils/constants/url";

const authProvider = {
  login: ({ username, password, captchaToken }) =>  {
    const request = new Request(BASE_URL + '/auth/login/admin', {
      method: 'POST',
      body: JSON.stringify({ username, password, captchaToken }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(response.statusText);
        }
        return response.json();
      })
      .then(auth => {
        localStorage.setItem('token', auth.token);
      })
      .catch(() => {
        return Promise.reject("Something went wrong, please try again");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return Promise.resolve();
  }
};

export default authProvider;
