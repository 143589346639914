export const parseArrayUuidToId = (data) => {
  if (!data?.length) return []

  return data.map(d => {
      const uuid = d.uuid;
      delete d.uuid;

      return ({
        ...d,
        livestreamMixedType: d?.type ?? d?.save_type,
        id: uuid
      })
    }
  )
}

export const parseObjectUuidToId = (datum) => ({
  ...datum,
  livestreamMixedType: datum?.type ?? datum?.save_type,
  id: datum.uuid
})
