import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import UserForm from "../../components/forms/UserForm";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";

const UserCreate = () => (
  <Create actions={<CreateActions/>} redirect="list">
    <SimpleForm redirect="list" toolbar={<CreateToolbar/>}>
      <UserForm/>
    </SimpleForm>
  </Create>
);

export default UserCreate;
