import React from 'react';
import { ListButton, TopToolbar } from "react-admin";

const CreateActions = () => (
  <TopToolbar>
    <ListButton label="Λιστα"/>
  </TopToolbar>
);

export default CreateActions;
