import React from "react";
import { FunctionField } from "react-admin";

const ClubDetailsField = ({ label, show }) => (
  <FunctionField
    label={label}
    emptyText='-'
    render={record => {
      const { org } = record || {};
      if (!org) return '-';
      const { name, user } = org || {};

      return show ?
        `${name} (${user?.firstName} ${user?.lastName} - ${user?.email})` :
        `${name} (${user?.email})`
    }}
  />
);

export default ClubDetailsField;
