import React from "react";
import { Edit, SimpleForm } from "react-admin";
import EditActions from "../../components/actionButtons/EditActions";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";
import ClubForm from "../../components/forms/ClubForm";

const ClubEdit = () => (
  <Edit actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <ClubForm edit />
    </SimpleForm>
  </Edit>
);

export default ClubEdit;
