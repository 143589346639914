import { stringifyUrl } from "query-string";
import httpClient from "../utils/helpers/httpClient";
import { ADMIN_API_BASE_URL } from "../utils/constants/url";
import {
  parseArrayUuidToId,
  parseObjectUuidToId,
} from "../utils/helpers/parseUuidToId";

export const livestreamProvider = {
  getList: (resource, params) => {
    const { filter, pagination, sort } = params;

    const urlWithQueryParams = stringifyUrl({
      url: `${ADMIN_API_BASE_URL}/${resource}`,
      query: {
        startDate: filter.startDate,
        endDate: filter.endDate,
        state: filter.state,
        page: pagination.page,
        perPage: pagination.perPage,
        order: sort.field === "id" ? undefined : sort.order,
        sortField: sort.field === "id" ? undefined : sort.field,
      },
    });

    return httpClient(urlWithQueryParams).then(({ headers, json }) => ({
      data: parseArrayUuidToId(json?.items),
      total: json?.total,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: parseObjectUuidToId(json),
      }),
    ),
};
