const LIVESTREAM_FIELDS = {
  name: {
    source: "name",
    label: "Name",
  },
  startAt: {
    source: "start_at",
    label: "Start Time",
  },
  endAt: {
    source: "end_at",
    label: "End Time",
  },
  homeTeamLogo: {
    source: "team_a_logo_url",
    label: "Home Team Logo",
  },
  homeTeam: {
    source: "team_a",
    label: "Home Team",
  },
  awayTeamLogo: {
    source: "team_b_logo_url",
    label: "Away Team Logo",
  },
  awayTeam: {
    source: "team_b",
    label: "Away Team",
  },
  livestreamMixedType:{
    source: "livestreamMixedType",
    label: "Livestream Mixed Type",
  },
  livestreamType: {
    source: "type",
    label: "Livestream Type",
  },
  livestreamSaveType: {
    source: "save_type",
    label: "Livestream Save Type",
  },

  state: {
    source: "state",
    label: "State",
  },
  creator: {
    source: "creatorId",
    label: "Creator",
  },
};

export default LIVESTREAM_FIELDS;
