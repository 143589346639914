import { DateTimeInput, SelectInput } from "react-admin";
import LIVESTREAM_STATES from "../../../utils/constants/livestreamStates";
import parseArrayToObjectWithId from "../../../utils/helpers/parseArrayToObjectWithId";

const LivestreamListFilters = () => [
  <DateTimeInput source="startDate" label="Released After"/>,
  <DateTimeInput source="endDate" label="Released Before"/>,
  <SelectInput
    source="state"
    label="By State"
    choices={parseArrayToObjectWithId(LIVESTREAM_STATES)}
  />
];

export default LivestreamListFilters;
