import httpClient from "../utils/helpers/httpClient";
import { BASE_URL } from "../utils/constants/url";
import { stringifyUrl } from 'query-string';

export const userRolesReferenceProvider = {
  getList: (resource, params) => {
    return httpClient(`${BASE_URL}/users/roles`).then(({ json }) => ({
        data: json,
        total: json?.length,
      })
    );
  },

  getMany: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${BASE_URL}/users/roles`,
      query: { roleIds: params.ids }
    })

    return httpClient(urlWithQueryParams).then(({ json }) => ({
        data: json,
        total: json.length,
      })
    );
  }
};
