import React from 'react';
import { useRecordContext, Labeled } from "react-admin";
import Typography from '@mui/material/Typography';

const UsernameField = ({ label, show }) => {
  const record = useRecordContext();
  if (!record?.firstName && !record?.lastName) return "-";
  const textToShow = `${record?.firstName} ${record?.lastName}`;

  return (
    <>
      {show ?
        <Labeled label={label}>
          <Typography variant="body2">
            {textToShow}
          </Typography>
        </Labeled> :
        <span>
          {textToShow}
        </span>
      }
    </>
  );
};

export default UsernameField;
