import React from 'react';
import { useCreateContext, useRedirect } from "react-admin";
import { Button } from "@mui/material";

const CreateToolbarCancelButton = () => {
  const { resource } = useCreateContext();
  const redirect = useRedirect();

  const handleCancel = () => {
    redirect(`/${resource}`)
  };

  return (
    <Button color="error" onClick={handleCancel}>Ακυρωση</Button>
  );
};

export default CreateToolbarCancelButton;
