import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  ImageField,
  ArrayField,
  SingleFieldList,
} from "react-admin";
import ListActions from "../../../components/actionButtons/ListActions";
import CLUB_FIELDS from "../../../utils/constants/labels/ClubFields";
import ClubListFiltersSearch from "./ClubListFiltersSearch";
import ChipsListEnumRecordField from "../../../components/fields/ChipsListEnumRecordField";

const ClubList = () => (
  <List
    filters={ClubListFiltersSearch()}
    pagination={false}
    actions={<ListActions />}
  >
    <Datagrid rowClick="show">
      <ImageField
        source={CLUB_FIELDS.logo.source}
        title={CLUB_FIELDS.logo.label}
        emptyText="-"
      />
      <TextField
        source={CLUB_FIELDS.name.source}
        label={CLUB_FIELDS.name.label}
        emptyText="-"
      />
      <TextField
        source={CLUB_FIELDS.country.source}
        label={CLUB_FIELDS.country.label}
        emptyText="-"
      />
      <TextField
        source={CLUB_FIELDS.city.source}
        label={CLUB_FIELDS.city.label}
        emptyText="-"
      />
      <TextField
        source={CLUB_FIELDS.owner.source}
        label={CLUB_FIELDS.owner.label}
        emptyText="-"
      />
      <TextField
        source={CLUB_FIELDS.user.source}
        label={CLUB_FIELDS.user.label}
        emptyText="-"
      />
      <ArrayField
        source={CLUB_FIELDS.teams.source}
        label={CLUB_FIELDS.teams.label}
      >
        <SingleFieldList />
      </ArrayField>
      <ChipsListEnumRecordField label={CLUB_FIELDS.scopes.label} />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export default ClubList;
