import React from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  TextInput,
  DateInput,
  number,
} from "react-admin";
import Show from "../Show/Show";
import USER_FIELDS from "../../utils/constants/labels/userFields";
import parseOwnerName from "../../utils/helpers/parseOwnerName";

const UserForm = ({ edit }) => {
  return (
    <div>
      <TextInput
        source={USER_FIELDS.firstName.source}
        label={USER_FIELDS.firstName.label}
        fullWidth
      />
      <TextInput
        source={USER_FIELDS.lastName.source}
        label={USER_FIELDS.lastName.label}
        fullWidth
      />
      <TextInput
        source={USER_FIELDS.email.source}
        label={USER_FIELDS.email.label}
        fullWidth
      />
      <TextInput
        source={USER_FIELDS.phoneNumber.source}
        label={USER_FIELDS.phoneNumber.label}
        fullWidth
        type={"tel"}
        validate={number("Must be number")}
      />
      <DateInput
        source={USER_FIELDS.dateOfBirth.source}
        label={USER_FIELDS.dateOfBirth.label}
        fullWidth
      />

      <ReferenceArrayInput
        source={USER_FIELDS.clubs.source}
        reference={USER_FIELDS.clubs.reference}
      >
        <AutocompleteArrayInput
          label={USER_FIELDS.clubs.label}
          fullWidth
          optionText={(record) => (
            <span>
              <b>{record?.name}</b>
              <i>{` ${parseOwnerName(record?.user)}`}</i>
            </span>
          )}
        />
      </ReferenceArrayInput>

      <Show if={edit}>
        <ReferenceArrayInput
          source={USER_FIELDS.roles.source}
          reference={USER_FIELDS.roles.reference}
        >
          <SelectArrayInput label={USER_FIELDS.roles.label} fullWidth />
        </ReferenceArrayInput>
      </Show>
    </div>
  );
};

export default UserForm;
