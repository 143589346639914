import React from "react";
import {
  ChipField,
  Labeled,
  ReferenceArrayField,
  Show,
  SingleFieldList,
  TextField,
} from "react-admin";
import UsernameField from "../../components/fields/UsernameField";
import USER_FIELDS from "../../utils/constants/labels/userFields";
import ShowActions from "../../components/actionButtons/ShowActions";
import { Card, Grid, Stack } from "@mui/material";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import MyChipField from "../../components/fields/MyChipField";

const UserShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <Card>
        <Stack>
          <ContainerPadding padding="1% 2% 1% 2%">
            <Grid container flexDirection="column">
              <UsernameField label={USER_FIELDS.fullName.label} show />

              <Labeled>
                <TextField
                  source={USER_FIELDS.email.source}
                  label={USER_FIELDS.email.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={USER_FIELDS.phoneNumber.source}
                  label={USER_FIELDS.phoneNumber.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={USER_FIELDS.dateOfBirth.source}
                  label={USER_FIELDS.dateOfBirth.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <ReferenceArrayField
                  source={USER_FIELDS.clubs.source}
                  label={USER_FIELDS.clubs.label}
                  reference={USER_FIELDS.clubs.reference}
                >
                  <SingleFieldList empty="-">
                    <MyChipField />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Labeled>

              <Labeled>
                <ReferenceArrayField
                  source={USER_FIELDS.roles.source}
                  label={USER_FIELDS.roles.label}
                  reference={USER_FIELDS.roles.reference}
                >
                  <SingleFieldList empty="-">
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Labeled>
            </Grid>
          </ContainerPadding>
        </Stack>
      </Card>
    </Show>
  );
};
export default UserShow;
