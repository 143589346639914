import React from "react";
import {
  List,
  Datagrid,
  EditButton,
  TextField,
  SingleFieldList,
  ChipField,
  DateField,
  ReferenceArrayField,
} from "react-admin";
import UsernameField from "../../../components/fields/UsernameField";
import ListActions from "../../../components/actionButtons/ListActions";
import USER_FIELDS from "../../../utils/constants/labels/userFields";
import UserListFiltersSearch from "./UserListFiltersSearch";

const UserList = () => (
  <List filters={UserListFiltersSearch()} actions={<ListActions />}>
    <Datagrid rowClick="show">

      <UsernameField label="Ονομα" />

      <TextField
        source={USER_FIELDS.email.source}
        label={USER_FIELDS.email.label}
        emptyText="-"
      />

      <DateField
        source={USER_FIELDS.lastLogin.source}
        label={USER_FIELDS.lastLogin.label}
        emptyText="-"
      />

      <ReferenceArrayField
        label={USER_FIELDS.clubs.label}
        reference={USER_FIELDS.clubs.reference}
        source={USER_FIELDS.clubs.source}
        empty="-"
      >
        <SingleFieldList empty={<span>-</span>}>
          <ChipField source={USER_FIELDS.clubs.sourceChip} />
        </SingleFieldList>
      </ReferenceArrayField>

      <ReferenceArrayField
        label={USER_FIELDS.roles.label}
        reference={USER_FIELDS.roles.reference}
        source={USER_FIELDS.roles.source}
      >
        <SingleFieldList empty={<span>-</span>}>
          <ChipField source={USER_FIELDS.roles.sourceChip} />
        </SingleFieldList>
      </ReferenceArrayField>

      <EditButton label="" />
    </Datagrid>
  </List>
);

export default UserList;
