import { stringifyUrl } from "query-string";
import httpClient from "../utils/helpers/httpClient";
import { ADMIN_API_BASE_URL } from "../utils/constants/url";

export const courtProvider = {
  getList: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${ADMIN_API_BASE_URL}/${resource}`,
      query: {
        page: params.pagination.page,
        perPage: params.pagination.perPage,
        q: params.filter.q,
      },
    });

    return httpClient(urlWithQueryParams).then(({ headers, json }) => ({
      data: json?.courts,
      total: json.metadata.totalCount,
    }));
  },

  create: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: { ...json } })),

  getOne: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      }),
    ),

  delete: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  update: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),
};
