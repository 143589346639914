import { stringifyUrl } from "query-string";
import httpClient from "../utils/helpers/httpClient";
import { ADMIN_API_BASE_URL } from "../utils/constants/url";
import { parseCreateClub } from "../utils/helpers/parseClub";

export const clubProvider = {
  getList: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${ADMIN_API_BASE_URL}/orgs`,
      query: { q: params.filter.q },
    });

    return httpClient(urlWithQueryParams).then(({ json: clubs }) => ({
      data: clubs,
      total: clubs?.length,
    }));
  },

  create: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/orgs`, {
      method: "POST",
      body: JSON.stringify(parseCreateClub(params.data)),
    }).then(({ json }) => ({ data: { ...json?.club } })),

  getOne: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/orgs/${params.id}`).then(
      ({ json }) => ({
        data: json,
      }),
    ),

  delete: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/orgs/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  update: (resource, params) =>
    httpClient(`${ADMIN_API_BASE_URL}/orgs/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(parseCreateClub(params.data)),
    }).then(({ json }) => ({ data: json })),

  getMany: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${ADMIN_API_BASE_URL}/orgs`,
    });

    return httpClient(urlWithQueryParams).then(({ json: clubs }) => ({
      data: clubs,
      total: clubs.length,
    }));
  },
};
