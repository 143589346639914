import React from "react";
import { ShowButton, ListButton } from "react-admin";
import StyledTopToolbar from "../styles/StyledTopToolbar";

const EditActions = () => (
  <StyledTopToolbar>
    <ShowButton label="Εμφανιση"/>
    <ListButton label="Λιστα"/>
  </StyledTopToolbar>
);

export default EditActions;
