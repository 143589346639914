import React, {useState, useRef} from 'react';
import {useLogin, useNotify, TextInput, PasswordInput, Form, required} from 'react-admin';
import {Button} from "@mui/material";
import ReCAPTCHA from 'react-google-recaptcha'
import LockIcon from '@mui/icons-material/Lock';
import loadingDots from '../../assets/images/loading-dots.svg'
import "./loginPage.css"

const LoginPage = () => {
    const [loading, setLoading] = useState(false)
    const reCaptchaRef = useRef()
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = async ({username, password}) => {
        const captchaToken = await reCaptchaRef.current.executeAsync()
        setLoading(true)

        login({username, password, captchaToken}).catch(() => {
            reCaptchaRef.current.reset()
            setLoading(false)
            notify('Invalid email or password')
        });
        reCaptchaRef.current.reset()
    };

    return (
        <div className="wrapper">
            <Form onSubmit={handleSubmit} className="form">
                <div className="formBody">
                    <LockIcon className="lockIcon"/>
                    <TextInput source="username" validate={[required()]} fullWidth/>
                    <PasswordInput source="password" validate={[required()]} fullWidth/>
                    <Button variant="contained" type="submit" fullWidth>
                        {!loading ? <span>SIGN IN</span> :
                            <img alt='img' src={loadingDots} className="loadingDots"/>}
                    </Button>
                </div>
            </Form>

            <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                size='invisible'
            />
        </div>
    )
}

export default LoginPage
