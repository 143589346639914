import React from "react";
import { FunctionField } from "react-admin";
import { Chip } from "@mui/material";

// Show list of chips when record is enum type.
// It hasn't any name/id fields, only enum names.
const ChipsListEnumRecordField = ({ label }) => (
  <FunctionField
    label={label}
    emptyText="-"
    render={(record) => (
      <div>
        {record.scopes.map((scope) => (
          <Chip key={scope} label={<span>{scope}</span>} />
        ))}
      </div>
    )}
  />
);

export default ChipsListEnumRecordField;
