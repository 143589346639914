import React from 'react';
import { Create, SimpleForm } from "react-admin";
import CourtForm from "../../components/forms/CourtForm";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";

const CourtCreate = () => (
  <Create actions={<CreateActions/>} redirect="list">
    <SimpleForm redirect="list" toolbar={<CreateToolbar/>}>
      <CourtForm/>
    </SimpleForm>
  </Create>
);

export default CourtCreate;
