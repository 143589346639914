const COURT_FIELDS = {
  name: {
    source: "name",
    label: "Name",
  },
  description: {
    source: "description",
    label: "Description",
  },
  courtConfigurationId: {
    source: "courtConfigurationId",
    label: "Court ID",
  },
  address: {
    source: "address",
    label: "Address",
  },
  publicIp: {
    source: "publicIp",
    label: "Public IP",
  },
  networkHardwareInstalled: {
    source: "networkHardwareInstalled",
    label: "Network Hardware Installed",
  },
  livestreamEnabled: {
    source: "livestreamEnabled",
    label: "Livestream Enabled",
  },
  supportsCommentary: {
    source: "supportsCommentary",
    label: "Supports Commentary",
  },
  cameraUsername: {
    source: "camera.username",
    label: "Username",
  },
  cameraPassword: {
    source: "camera.password",
    label: "Password",
  },
  cameraInstalled: {
    source: "camera.installed",
    label: "Installed",
  },
  cameraRtspPort: {
    source: "camera.rtspPort",
    label: "Rtsp Port",
  },
  cameraHasMic: {
    source: "camera.hasMic",
    label: "Has Mic",
  },
  cameraHasLineIn: {
    source: "camera.hasLineIn",
    label: "Has Line In",
  },
  livestreamSettingsFullEnabled: {
    source: "livestreamSettings.fullEnabled",
    label: "Is Full Enabled",
  },
  livestreamSettingsLiteEnabled: {
    source: "livestreamSettings.liteEnabled",
    label: "Is Lite Enabled",
  },
  livestreamSettingsStaticEnabled: {
    source: "livestreamSettings.staticEnabled",
    label: "Is Static Enabled",
  },
  livestreamSettingsCommentaryEnabled: {
    source: "livestreamSettings.commentaryEnabled",
    label: "Is Commentary Enabled",
  },
};

export default COURT_FIELDS;
