import React from "react";
import { Create, SimpleForm } from "react-admin";
import CreateActions from "../../components/actionButtons/CreateActions";
import CreateToolbar from "../../components/toolbars/CreateToolbar/CreateToolbar";
import ClubForm from "../../components/forms/ClubForm";

const ClubCreate = () => (
  <Create actions={<CreateActions />} redirect="list">
    <SimpleForm redirect="list" toolbar={<CreateToolbar />}>
      <ClubForm />
    </SimpleForm>
  </Create>
);

export default ClubCreate;
