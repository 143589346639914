import React from "react";
import { DeleteButton, EditButton, ListButton } from "react-admin";
import StyledTopToolbar from "../styles/StyledTopToolbar";

const ShowActions = () => (
  <StyledTopToolbar>
    <EditButton label="Επεξεργασια"/>
    <ListButton label="Λιστα"/>
    <DeleteButton label="Διαγραφη"/>
  </StyledTopToolbar>
);

export default ShowActions;
