import React from 'react';
import { Edit, SimpleForm } from "react-admin";
import CourtForm from "../../components/forms/CourtForm";
import EditActions from "../../components/actionButtons/EditActions";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";

const CourtEdit = () => (
  <Edit actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <CourtForm/>
    </SimpleForm>
  </Edit>
);

export default CourtEdit;
