const LIVESTREAM_STATES = [
  'scheduled',
  'creating',
  'running',
  'deleting',
  'finished',
  'paused',
  'cancelled',
  'errored'
];

export default LIVESTREAM_STATES;
