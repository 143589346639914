import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  BooleanField,
  TextField,
} from "react-admin";
import ListActions from "../../../components/actionButtons/ListActions";
import CourtListFiltersSearch from "./CourtListFiltersSearch";
import COURT_FIELDS from "../../../utils/constants/labels/courtFields";

const CourtList = () => (
  <List filters={CourtListFiltersSearch()} actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField
        source={COURT_FIELDS.name.source}
        label={COURT_FIELDS.name.label}
        emptyText="-"
      />
      <TextField
        source={COURT_FIELDS.description.source}
        label={COURT_FIELDS.description.label}
        emptyText="-"
      />
      <TextField
        source={COURT_FIELDS.address.source}
        label={COURT_FIELDS.address.label}
        emptyText="-"
      />
      <TextField
        source={COURT_FIELDS.publicIp.source}
        label={COURT_FIELDS.publicIp.label}
        emptyText="-"
      />
      <BooleanField
        source={COURT_FIELDS.livestreamEnabled.source}
        label={COURT_FIELDS.livestreamEnabled.label}
      />
      <BooleanField
          source={COURT_FIELDS.supportsCommentary.source}
          label={COURT_FIELDS.supportsCommentary.label}
      />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export default CourtList;
