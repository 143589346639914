import { SaveButton, DeleteButton } from "react-admin";
import StyledToolbar from "../../styles/StyledToolbar";

const EditToolbar = () => (
  <StyledToolbar>
    <SaveButton label="Αποθηκευση"/>
    <DeleteButton label="Διαγραφη"/>
  </StyledToolbar>
);

export default EditToolbar;
