const USER_FIELDS = {
  firstName: {
    source: "firstName",
    label: "FirstName",
  },
  lastName: {
    source: "lastName",
    label: "LastName",
  },
  fullName: {
    source: "",
    label: "Full Name",
  },
  email: {
    source: "email",
    label: "Email",
  },
  phoneNumber: {
    source: "phoneNumber",
    label: "Phone Number",
  },
  dateOfBirth: {
    source: "dateOfBirth",
    label: "Date Of Birth",
  },
  lastLogin: {
    source: "lastLogin",
    label: "Last Login",
  },
  roles: {
    source: "roleIds",
    sourceChip: 'name',
    label: "Roles",
    reference: "userRoles",
  },
  clubs: {
    source: "clubIds",
    sourceChip: 'name',
    label: "Clubs",
    reference: "clubs",
  },
};

export default USER_FIELDS;
