import httpClient from "../utils/helpers/httpClient";
import { BASE_URL } from "../utils/constants/url";
import { stringifyUrl } from "query-string";
import parseClubScopes from "../utils/helpers/parseClubScope";

export const clubScopesReferenceProvider = {
  getList: () => {
    const urlWithQueryParams = stringifyUrl({
      url: `${BASE_URL}/clubs/club-scopes`,
    });

    return httpClient(urlWithQueryParams).then(({ json }) => ({
      data: parseClubScopes(json),
      total: json?.length,
    }));
  },

  getMany: () => {
    // In this case we don't need to make a request as the clubScopes is an enum.
    return Promise.resolve({ data: [], total: 0 });
  },
};
