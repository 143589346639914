import React from "react";
import {
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import COURT_FIELDS from "../../utils/constants/labels/courtFields";
import parseOwnerName from "../../utils/helpers/parseOwnerName";

const CourtForm = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.name.source}
            label={COURT_FIELDS.name.label}
            fullWidth
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.description.source}
            label={COURT_FIELDS.description.label}
            fullWidth
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.courtConfigurationId.source}
            label={COURT_FIELDS.courtConfigurationId.label}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} sm={12}>
          <TextInput
            source={COURT_FIELDS.address.source}
            label={COURT_FIELDS.address.label}
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <TextInput
            source={COURT_FIELDS.publicIp.source}
            label={COURT_FIELDS.publicIp.label}
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <TextInput
            source={COURT_FIELDS.networkHardwareInstalled.source}
            label={COURT_FIELDS.networkHardwareInstalled.label}
            fullWidth
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.livestreamEnabled.source}
            label={COURT_FIELDS.livestreamEnabled.label}
          />
        </Grid>
      </Grid>

      <ReferenceInput source="clubId" reference="clubs">
        <AutocompleteInput
          fullWidth
          label="Club"
          optionText={(record) => (
            <span>
              <b>{record?.name}</b>
              <i>{`${parseOwnerName(record?.owner)}`}</i>
            </span>
          )}
          inputText={(record) =>
            `${record?.name} ${parseOwnerName(record?.owner)}`
          }
        />
      </ReferenceInput>

      <Grid container justifyContent="center">
        <Typography variant="h6" sx={{ paddingTop: 0, paddingBottom: "10px" }}>
          Camera Settings
        </Typography>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.cameraUsername.source}
            label={COURT_FIELDS.cameraUsername.label}
            fullWidth
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.cameraPassword.source}
            label={COURT_FIELDS.cameraPassword.label}
            fullWidth
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <TextInput
            source={COURT_FIELDS.cameraInstalled.source}
            label={COURT_FIELDS.cameraInstalled.label}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item md={4} sm={12}>
          <NumberInput
            source={COURT_FIELDS.cameraRtspPort.source}
            label={COURT_FIELDS.cameraRtspPort.label}
            fullWidth
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.cameraHasMic.source}
            label={COURT_FIELDS.cameraHasMic.label}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.cameraHasLineIn.source}
            label={COURT_FIELDS.cameraHasLineIn.label}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Typography variant="h6" sx={{ paddingTop: 0, paddingBottom: "10px" }}>
          Livestream Settings
        </Typography>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item md={3} sm={12}>
          <BooleanInput
              source={COURT_FIELDS.livestreamSettingsCommentaryEnabled.source}
              label={COURT_FIELDS.livestreamSettingsCommentaryEnabled.label}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.livestreamSettingsFullEnabled.source}
            label={COURT_FIELDS.livestreamSettingsFullEnabled.label}
            disabled
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.livestreamSettingsLiteEnabled.source}
            label={COURT_FIELDS.livestreamSettingsLiteEnabled.label}
            disabled
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <BooleanInput
            source={COURT_FIELDS.livestreamSettingsStaticEnabled.source}
            label={COURT_FIELDS.livestreamSettingsStaticEnabled.label}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CourtForm;
