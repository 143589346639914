const RESOURCES = {
    users: 'users',
    clubs: 'clubs',
    courts: 'courts',
    livestreams: 'livestreams',
    userRoles: 'userRoles',
    clubScopes: 'clubScopes',
    countries: 'countries',
}

export default RESOURCES