const CLUB_FIELDS = {
  logo: {
    source: "logoUrl",
    label: "Logo",
    title: "club logo image",
  },
  name: {
    source: "name",
    label: "Name",
  },
  city: {
    source: "city",
    label: "City",
  },
  owner: {
    source: "owner.email",
    label: "Owner",
  },
  user: {
    source: "user.email",
    label: "User",
  },
  teams: {
    source: "teams",
    label: "Teams",
  },
  country: {
    source: "country.name",
    label: "Country",
    reference: "countries",
    referenceSource: "country.id",
  },
  scopes: {
    source: "scopes",
    label: "Scopes",
    reference: "clubScopes",
    referenceSource: "scopes",
  },
};

export default CLUB_FIELDS;
