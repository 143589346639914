import React from "react";
import {
  ArrayField,
  ImageField,
  Labeled,
  Show,
  SingleFieldList,
  TextField,
} from "react-admin";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import { Card, Grid, Stack } from "@mui/material";
import ShowActions from "../../components/actionButtons/ShowActions";
import CLUB_FIELDS from "../../utils/constants/labels/ClubFields";
import ChipsListEnumRecordField from "../../components/fields/ChipsListEnumRecordField";

const ClubShow = () => {
  return (
    <Show actions={<ShowActions />}>
      <Card>
        <Stack>
          <ContainerPadding padding="1% 2% 1% 2%">
            <Grid container flexDirection="column">
              <Labeled>
                <ImageField
                  source={CLUB_FIELDS.logo.source}
                  title={CLUB_FIELDS.logo.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CLUB_FIELDS.name.source}
                  label={CLUB_FIELDS.name.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CLUB_FIELDS.country.source}
                  label={CLUB_FIELDS.country.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CLUB_FIELDS.city.source}
                  label={CLUB_FIELDS.city.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CLUB_FIELDS.owner.source}
                  label={CLUB_FIELDS.owner.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <TextField
                  source={CLUB_FIELDS.user.source}
                  label={CLUB_FIELDS.user.label}
                  emptyText="-"
                />
              </Labeled>

              <Labeled>
                <ArrayField
                  source={CLUB_FIELDS.teams.source}
                  label={CLUB_FIELDS.teams.label}
                >
                  <SingleFieldList />
                </ArrayField>
              </Labeled>

              <Labeled>
                <ChipsListEnumRecordField label={CLUB_FIELDS.scopes.label} />
              </Labeled>
            </Grid>
          </ContainerPadding>
        </Stack>
      </Card>
    </Show>
  );
};
export default ClubShow;
