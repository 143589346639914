import React from 'react';
import { Edit, SimpleForm } from "react-admin";
import EditActions from "../../components/actionButtons/EditActions";
import EditToolbar from "../../components/toolbars/EditToolbar/EditToolbar";
import UserForm from "../../components/forms/UserForm";

const UserEdit = () => (
  <Edit actions={<EditActions/>}>
    <SimpleForm toolbar={<EditToolbar/>}>
      <UserForm edit/>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
