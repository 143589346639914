import { stringifyUrl } from "query-string";
import httpClient from "../utils/helpers/httpClient";
import { BASE_URL } from "../utils/constants/url";

export const userProvider = {
  getList: (resource, params) => {
    const { pagination, filter, sort } = params || {};
    const { order, field } = sort || {};

    const urlWithQueryParams = stringifyUrl({
      url: `${BASE_URL}/${resource}`,
      query: {
        page: pagination.page,
        perPage: pagination.perPage,
        q: filter.q,
        sortOrder: order,
        sortField: field,
      },
    });

    return httpClient(urlWithQueryParams).then(({ json }) => ({
      data: json.users,
      total: json.metadata.totalCount,
    }));
  },

  create: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: {
        ...json,
        contacts: [{ type: "EMAIL" }, { type: "PHONE_NUMBER" }],
        positions: json.personalData?.positions?.map((position) => position.id),
      },
    })),

  getOne: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  delete: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: {} })),

  update: (resource, params) =>
    httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  getMany: (resource, params) => {
    const urlWithQueryParams = stringifyUrl({
      url: `${BASE_URL}/${resource}`,
      query: { userIds: params.ids },
    });

    return httpClient(urlWithQueryParams).then(({ json }) => ({
      data: json?.users,
      total: json?.users?.length,
    }));
  },
};
