import React from "react";
import {
  BooleanField,
  Show,
  TextField,
  NumberField,
  Labeled,
  ReferenceField,
  FunctionField,
} from "react-admin";
import ShowActions from "../../components/actionButtons/ShowActions";
import { Card, Grid, Stack, Typography } from "@mui/material";
import COURT_FIELDS from "../../utils/constants/labels/courtFields";
import ContainerPadding from "../../components/layout/containerPadding/ContainerPadding";
import parseOwnerName from "../../utils/helpers/parseOwnerName";

const CourtShow = () => (
  <Show actions={<ShowActions />}>
    <Card>
      <Stack>
        <ContainerPadding padding="1% 2% 1% 2%">
          <Grid container spacing={2}>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.name.source}
                  label={COURT_FIELDS.name.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.description.source}
                  label={COURT_FIELDS.description.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.courtConfigurationId.source}
                  label={COURT_FIELDS.courtConfigurationId.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.address.source}
                  label={COURT_FIELDS.address.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.publicIp.source}
                  label={COURT_FIELDS.publicIp.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.networkHardwareInstalled.source}
                  label={COURT_FIELDS.networkHardwareInstalled.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item md={4} sm={12}>
              <Labeled>
                <BooleanField
                  source={COURT_FIELDS.livestreamEnabled.source}
                  label={COURT_FIELDS.livestreamEnabled.label}
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <BooleanField
                  source={COURT_FIELDS.supportsCommentary.source}
                  label={COURT_FIELDS.supportsCommentary.label}
                />
              </Labeled>
            </Grid>
          </Grid>

          <Labeled>
            <ReferenceField
              label="Club"
              reference="clubs"
              source="clubId"
              link={false}
              emptyText="-"
            >
              <FunctionField
                render={(record) =>
                  `${record?.name} ${parseOwnerName(record?.owner)}`
                }
              />
            </ReferenceField>
          </Labeled>

          <Grid container justifyContent="center">
            <Typography
              variant="h6"
              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              Camera Settings
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.cameraUsername.source}
                  label={COURT_FIELDS.cameraUsername.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.cameraPassword.source}
                  label={COURT_FIELDS.cameraPassword.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <TextField
                  source={COURT_FIELDS.cameraInstalled.source}
                  label={COURT_FIELDS.cameraInstalled.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4} sm={12}>
              <Labeled>
                <NumberField
                  source={COURT_FIELDS.cameraRtspPort.source}
                  label={COURT_FIELDS.cameraRtspPort.label}
                  emptyText="-"
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <BooleanField
                  source={COURT_FIELDS.cameraHasMic.source}
                  label={COURT_FIELDS.cameraHasMic.label}
                />
              </Labeled>
            </Grid>
            <Grid item md={4} sm={12}>
              <Labeled>
                <BooleanField
                  source={COURT_FIELDS.cameraHasLineIn.source}
                  label={COURT_FIELDS.cameraHasLineIn.label}
                />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Typography variant="h6" sx={{ paddingTop: 0, paddingBottom: "10px" }}>
              Livestream Settings
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={3} sm={12}>
              <Labeled>
                <BooleanField
                    source={COURT_FIELDS.livestreamSettingsCommentaryEnabled.source}
                    label={COURT_FIELDS.livestreamSettingsCommentaryEnabled.label}
                />
              </Labeled>
            </Grid>
            <Grid item md={3} sm={12}>
              <Labeled>
                <BooleanField
                    source={COURT_FIELDS.livestreamSettingsFullEnabled.source}
                    label={COURT_FIELDS.livestreamSettingsFullEnabled.label}
                />
              </Labeled>
            </Grid>
            <Grid item md={3} sm={12}>
              <Labeled>
                <BooleanField
                    source={COURT_FIELDS.livestreamSettingsLiteEnabled.source}
                    label={COURT_FIELDS.livestreamSettingsLiteEnabled.label}
                />
              </Labeled>
            </Grid>
            <Grid item md={3} sm={12}>
              <Labeled>
                <BooleanField
                    source={COURT_FIELDS.livestreamSettingsStaticEnabled.source}
                    label={COURT_FIELDS.livestreamSettingsStaticEnabled.label}
                />
              </Labeled>
            </Grid>
          </Grid>
        </ContainerPadding>
      </Stack>
    </Card>
  </Show>
);

export default CourtShow;
