import * as React from 'react';
import { FilterButton, CreateButton, ExportButton, TopToolbar } from 'react-admin';

const ListActions = () => (
  <TopToolbar>
    <FilterButton label="Προσθήκη Φίλτρου"/>
    <CreateButton label="Δημιουργια"/>
    <ExportButton label="Εξαγωγη"/>
  </TopToolbar>
);

export default ListActions;
