import { SaveButton } from "react-admin";
import CreateToolbarCancelButton from "./CreateToolbarCancelButton/CreateToolbarCancelButton";
import StyledToolbar from "../../styles/StyledToolbar";

const CreateToolbar = () => (
  <StyledToolbar>
    <SaveButton label="Αποθηκευση"/>
    <CreateToolbarCancelButton/>
  </StyledToolbar>
);

export default CreateToolbar;
