import React from "react";
import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";
import parseOwnerName from "../../utils/helpers/parseOwnerName";

const MyChipField = () => {
  const record = useRecordContext();

  return (
    <Chip
      label={
        <span>
          <b>{record?.name}</b>
          <i>{` ${parseOwnerName(record?.user)}`}</i>
        </span>
      }
    />
  );
};

export default MyChipField